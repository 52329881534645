<template>
  <div class="cs-container" style="background: white">
    <div
      class="d-print-none mb-2 flex items-center justify-end"
      style="margin-right: 80px"
    >
      <b-button variant="primary" @click="handlePrint">In Phiếu</b-button>
    </div>

    <header-form></header-form>

    <div class="d-flex align-items-center justify-content-center mx-auto w-90">
      <!-- <img class="cs-img col-6" src="@/assets/images/pages/AGTC-logo.png" /> -->
      <span class="close-icon d-print-none" @click="backToDetail">&times;</span>
    </div>

    <div id="printOrderForm" class="my-3">
      <b-row>
        <b-col>
          <div>
            <div class="cs-flex">
              <span class="cs-title"
                >PHẦN 1: THÔNG TIN CỦA NGƯỜI LÀM XÉT NGHIỆM</span
              >
            </div>
            <div class="cs-flex cs-border-b gap-0">
              <div class="col-4 p-0">
                <span class="cs-label bold">Họ tên: </span>
                <span class="cs-content" v-html="form.full_name"></span>
              </div>
              <div class="col-4 p-0">
                <span class="cs-label bold">Ngày sinh: </span>
                <span class="cs-content" v-html="formatDMY(form.birthday)"></span>
              </div>
              <div class="col-4 p-0">
                <span class="cs-label bold">Điện thoại: </span>
                <span class="cs-content" v-html="form.phone_number"></span>
              </div>
            </div>
            <div class="cs-flex cs-border-b gap-0">
              <div class="col-8 p-0">
                <span class="cs-label bold">Địa chỉ: </span>
                <span class="cs-content" v-html="form.address"></span>
              </div>
              <div class="col-4 p-0">
                <span class="cs-label bold">Email: </span>
                <span class="cs-content" v-html="form.email"></span>
              </div>
            </div>
            <div class="cs-flex cs-border-b gap-0">
              <div class="col-4 p-0">
                <span class="cs-label bold">Phòng khám: </span>
                <span class="cs-content" v-html="form.clinic"></span>
              </div>
              <div class="col-4 p-0">
                <span class="cs-label bold">Bác sĩ: </span>
                <span class="cs-content" v-html="form.doctor_name"></span>
              </div>
              <div class="col-4 p-0">
                <span class="cs-label bold">Điện thoại: </span>
                <span class="cs-content" v-html="form.doctor_phone_number"></span>
              </div>
            </div>
            <div class="cs-flex cs-border-b">
              <div class="col-12 p-0">
                <span class="cs-label bold"
                  >Thông tin lâm sàng/Kết quả chẩn đoán:
                </span>
                <span class="cs-content" v-html="form.diagnostic_results"></span>
                <template v-if="!form.diagnostic_results">
                  <br /><br /><br /><br />
                </template>
              </div>
            </div>
            <div class="cs-flex">
              <span class="cs-label bold mr-1">Dạng mẫu: </span>
              <div class="d-flex cs-content">
                <div
                  class="mr-1 cs-checkbox"
                  :set="(isMale = form.diagnose_type === 1)"
                >
                  <input
                    v-model="isMale"
                    id="male"
                    name="male"
                    type="checkbox"
                  />
                  <label>Dịch ối/gai nhau</label>
                </div>
                <div
                  class="mr-1 cs-checkbox"
                  :set="(isFemale = form.diagnose_type === 2)"
                >
                  <input
                    v-model="isFemale"
                    id="female"
                    name="female"
                    type="checkbox"
                  />
                  <label>Máu/ dịch</label>
                </div>
                <div
                  class="mr-1 cs-checkbox"
                  :set="(isFemale = form.diagnose_type === 3)"
                >
                  <input
                    v-model="isFemale"
                    id="female"
                    name="female"
                    type="checkbox"
                  />
                  <label>Mô</label>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div class="cs-flex">
              <span class="cs-title">PHẦN 2: THỎA THUẬN SỬ DỤNG DỊCH VỤ</span>
            </div>
            <div class="cs-flex">
              <span class="cs-label">
                Những điểm quan trọng người tham gia xét nghiệm cần biết trước khi đồng ý tham gia thực hiện CNVSure:
              </span>
            </div>
            <div class="cs-flex">
              <span class="cs-label bold">
                A. Mục đích của xét nghiệm:  
              </span>
            </div>
            <div class="cs-flex">
              <span class="cs-label">
                Xét nghiệm của <b>CNVSure</b>: xác định lệch bội của 24 NST và tất cả vi mất đoạn, vi lặp đoạn có kích thước từ <b>400kb trở lên</b>
              </span>
            </div>
            <div class="cs-flex">
              <span class="cs-label bold">
                B. Nguyên lý của xét nghiệm:  
              </span>
            </div>
            <div class="cs-flex">
              <span class="cs-label">
                Xét nghiệm CNVsure tìm kiếm những bất thường trên NST (lệch bội, vi mất đoạn, vi lặp đoạn) bằng cách phân tách DNA (vật liệu di truyền) từ nhiều dạng mẫu, bao gồm: dịch ối, gai nhau, máu, mô. DNA sau khi được phân tách sẽ được tiến hành giải trình tự thế hệ mới, phân tích bằng phần mềm chuyên biệt để nhận dạng các bất thường di truyền.
              </span>
            </div>
            <div class="cs-flex">
              <span class="cs-label bold">
                C. Hạn chế của xét nghiệm:  
              </span>
            </div>
            <ul>
              <li class="col-12">
                Xét nghiệm của CNVsure chỉ có khả năng phát hiện thể toàn phần của lệch bội 24 NST, tất cả vi mất đoạn, vi lặp đoạn có kích thước từ 400kb trở lên và không nhằm mục đích phát hiện thể khảm hoặc bất thường cấu trúc NST.
              </li>
              <li class="col-12">
                Xét nghiệm không thể phát hiện các mất đoạn/lặp đoạn nhỏ hơn 400kb, các bất thường cân bằng như đảo đoạn/chuyển đoạn và đột biến điểm trên gen.
              </li>
              <li class="col-12">
                Độ tin cậy cảu xét nghiệm sẽ KHÔNG đảm bảo nếu mẫu bị nhiễm nhiều từ các nguồn khác (mẫu của con bị nhiễm quá nhiều các nguồn từ mẹ).
              </li>
            </ul>
            <div class="cs-flex">
              <span class="cs-label bold">
                D. Nghiên cứu:  
              </span>
            </div>
            <div class="cs-flex">
              <span class="cs-label">
                Chúng tôi có thể sử dụng mẫu dư và các thông tin đã loại bỏ danh tính của người làm xét nghiệm vào mục đích nghiên cứu. Các nghiên cứu này có thể được công bố trên các tạp chí khoa học hoặc sử dụng để phát triển các sản phẩm và dịch vụ của chúng tôi. Người làm xét nghiệm sẽ không được thông báo hoặc trả tiền cho các hoạt động nghiên cứu này.  
              </span>
            </div>
          </div>

          <div>
            <div class="cs-flex">
              <span class="cs-title"
                >PHẦN 3: ĐỒNG Ý CỦA NGƯỜI LÀM XÉT NGHIỆM VÀ BÁC SĨ
              </span>
            </div>

            <div class="cs-width-full cs-flex">
              <div class="container-border cs-width-full">
                <div class="d-flex">
                  <div class="col-6 p-0 cs-border-r">
                    <div class="cs-flex">
                      Người tham gia xét nghiệm đã đọc, hiểu rõ và đồng ý với nguy cơ và hạn chế của xét nghiệm được ghi trong phần 2 của phiếu yêu cầu xét nghiệm.
                    </div>
                    <div class="cs-flex">Ngày.......tháng.......năm.......</div>
                  </div>
                  <div class="col-6 p-0 cs-border-r">
                    <div class="cs-flex">Người làm xét nghiệm đã được giải thích về hạn chế của xét nghiệm được ghi ở trong phần 2 của phiếu yêu cầu xét nghiệm này.</div>
                    <div class="cs-flex">Ngày.......tháng.......năm.......</div>
                  </div>
                </div>
                <div class="d-flex cs-border-t">
                  <div class="col-6 p-0 cs-border-r">
                    <div class="cs-flex">
                      Chữ ký người tham gia xét nghiệm<br/><br/><br/><br/>
                    </div>
                  </div>
                  <div class="col-6 p-0 cs-border-r">
                    <div class="cs-flex">Chữ ký bác sĩ tư vấn</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
    
<script>
import { DEFAULT_CIPHERS } from "tls";
import { mapState } from "vuex";
import HeaderForm from "@/views/PrintOrderForm/HeaderForm.vue"
import moment from 'moment'

const DEFAULT_FORM = {
  full_name: "",
  birthday: "",
  gender: null,
  address: "",
  phone_number: "",
  doctor: "",
  clinic: "",
  clinic_phone_number: "",
  sample_taken_at: "",
  test_code: "",
  requirements: [],
  cancer_history: [],
};

export default {
  name: "PrintOrderConsentForm",
  components: {
    HeaderForm
  },
  data() {
    return {
      form: { ...DEFAULT_FORM },
      DEFAULT_FORM,
      moment
    };
  },
  computed: {
    ...mapState({
      orders: (state) => state.orders,
    }),
    dateNow() {
      const date = new Date();
      return `Ngày ${date.getDate()} tháng ${
        date.getMonth() + 1
      } năm ${date.getFullYear()}`;
    },
  },
  created() {
    if (this.$route.params.id) {
      this.handleMapData();
    }
  },
  methods: {
    formatDMY (date) {
      return date ? window.moment(date).format('DD/MM/YYYY') : ""
    },
    handleMapData() {
      this.form = this.orders.requisitionFormV2 || { ...DEFAULT_FORM };
    },
    handlePrint() {
      document.title = "Blood-sample-form";
      window.print();
    },
    backToDetail() {
      document.title = "HODO Partner";
      this.$router.replace({
        name: "orderDetail",
        params: {
          id: this.orders.viewDetail.id
        },
        query: {
          step: 1,
        },
      });
    },
    handleFormatDate(date) {
      if (!date) return "";
      return moment(date).format("DD-MM-YYYY");
    },
  },
};
</script>
    
<style lang="scss" scoped>
.cs-container {
  position: relative;
  overflow: hidden;
  padding: 20px;
  box-sizing: border-box;

  * {
    color: black;
    font-family: "Times New Roman", "Tinos", Times, serif !important;
    font-size: 18px;
  }

  #printOrderForm {
    margin: auto;
    // margin-top: 100px;
    padding-left: 40px;
    padding-right: 40px;
  }

  .w-90 {
    width: 90%;
  }
  .w-80 {
    width: 80%;
  }
  .w-30 {
    width: 30%;
  }

  h2 {
    // color: black;
    font-size: 28px;
  }
}

.container-border {
  border: 1px solid #5c5c5c;
  padding: 0;
  width: 50%;
}

.cs-title {
  display: block;
  width: 100%;
  font-size: 22px;
  font-weight: 400;
  text-transform: uppercase;
  padding: 1px 6px;
  border: 1px solid #5c5c5c;
  background: #0569C5;
  color: white;
}

.cs-flex {
  display: flex;
  gap: 8px;
  padding: 6px;
  box-sizing: border-box;
  align-items: center;
  .flex-grow-4 {
    flex-grow: 4;
  }
  .flex-grow-2 {
    flex-grow: 2;
  }
  &.gap-0 {
    gap: 0px;
  }
}

// .cs-label {
// width: 150px;
// }
.bold {
  font-weight: bold;
}

.cs-border-b {
  border-bottom: 1px solid #5c5c5c;
}
.cs-border-t {
  border-top: 1px solid #5c5c5c;
}

.cs-border-l {
  border-left: 1px solid #5c5c5c;
}

.cs-border-r {
  border-right: 1px solid #5c5c5c;
}

.cs-width-full {
  width: 100% !important;
}

.cs-font-bold {
  font-weight: bold;
}

.cs-checkbox {
  pointer-events: none;
  display: flex;
  align-items: baseline;
  input {
    transform: translateY(3px);
    width: 18px;
    height: 18px;
  }

  input[type="checkbox"] {
    accent-color: #20419b;
  }

  label {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    margin-left: 6px;
  }
}

.cs-content {
  flex: 1;
}

.min-checkbox {
  min-width: 120px;
}
.box-content {
  display: block;
  padding: 6px;
  min-height: 120px;
}
.html2pdf__page-break {
  page-break-before: always;
}

.cs-padding {
  padding: 6px;
}

.cs-border {
  border: 1px solid #5c5c5c;
}

.content-none {
  width: 100%;
  border-bottom: 2px dashed;
}

.cs-text-sm {
  font-size: 11px;
  font-weight: 500;
}

.cs-text-md {
  font-size: 16px;
}

.cs-list {
  display: flex;
  gap: 12px;
}

.cs-ol {
  li {
    position: relative;
    padding-left: 10px;
    margin-left: 15px;
  }

  li:before {
    position: absolute;
    left: -2px;
  }
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 20px;
  color: black;
  font-size: 40px;
  cursor: pointer;
}

.cs-img {
  width: 300px;
}

.title-color {
  color: rgb(196, 91, 19);
  font-size: 36px !important;
}

@media print {
  body,
  html {
    height: 100vh;
    *  {
      color: black !important;
    }
  }
  .cs-title {
    color: white !important;
  }

  .hidden-print {
    border: none;
    outline: none;
    background: transparent;
  }

  #BrowserPrintDefaults {
    display: none;
  }

  #printOrderForm {
    max-width: 1000px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .html2pdf__page-break {
    page-break-before: always;
  }

  @page {
    margin: 0;
  }
}
</style>